/* eslint-disable import/no-named-as-default-member */
import { isEmpty } from 'lodash';
import Constants, { StaffType, AccountMaxInvitedStaff, Status, Url } from './constants';

export const EmailIsValid = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const IsAdminOrContributor = (staffType) => [StaffType.Admin, StaffType.Contributor].includes(staffType);

export const IsAdminOnly = (staffType) => [StaffType.Admin].includes(staffType);

export const IsArray = (obj) => {
  if (Array.isArray(obj)) {
    return true;
  }
  return false;
};

export const IsEmpty = (obj) => {
  if (obj === null || obj === undefined || typeof obj === 'undefined' || obj === '' || obj === 'undefined' || (Object.keys(obj).length === 0 && obj.constructor === Object)) {
    return true;
  } else if (Array.isArray(obj) && obj.length === 0) {
    return true;
  } else {
    return false;
  }
};

export function isValidUrl(obj) {
  if (obj.indexOf('http://') === 0 || obj.indexOf('https://') === 0) {
    return true;
  } else if (isEmpty(obj)) {
    return true;
  } else {
    return false;
  }
}

export function IsValidHexColor(hexCode) {
  // Define the regular expression pattern for a 6-character hex color code
  const hexColorPattern = /^[0-9A-Fa-f]{6}$/;
  return hexColorPattern.test(hexCode);
}

export function IsNumber(obj) {
  if (typeof obj === 'number' && !isNaN(obj)) {
    return true;
  } else {
    return false;
  }
}

export const IsString = (obj) => typeof obj === 'string';

export const IsTrue = (obj) => {
  if (obj === true || obj === 'true') {
    return true;
  } else {
    return false;
  }
};

export const IfEmptyThrowError = (obj, errorMsg) => {
  if (IsEmpty(obj)) {
    throw new Error(errorMsg);
  }
};

export const IfFalseThrowError = (flag, errorMsg) => {
  if (flag === false) {
    throw new Error(errorMsg);
  }
};

export const IfNotEmptyThrowError = (obj, errorMsg) => {
  if (!IsEmpty(obj)) {
    throw new Error(errorMsg);
  }
};

export const IfTrueThrowError = (flag, errorMsg) => {
  if (flag === true) {
    throw new Error(errorMsg);
  }
};

export const IsValidUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const HasReachedStaffLimit = (plan, staffsList) => {
  IfFalseThrowError(Object.keys(AccountMaxInvitedStaff).includes(plan), Constants.Error.AccountPlanIsInvalid);

  const pendingAndActivatedStaffsCount = staffsList.filter((staff) => [Status.Activated, Status.Pending].includes(staff.status)).length;

  const maxNoStaff = AccountMaxInvitedStaff[plan];

  return pendingAndActivatedStaffsCount >= maxNoStaff;
};

export const isPageOnlyForUnauthorizedUsers = (path) => (
  !IsEmpty(path) ? [
    Url.Signup,
    Url.Login,
    Url.ForgetPassword,
    Url.ResetPassword,
    Url.UserVerificationSent,
    Url.UserVerification,
    Url.Main,
  ].includes(path) : false
);
