import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import Api from '../services/apiService';
import { IsEmpty } from '../common/checks';

const UserVerificationSent = () => {
  const [email, setEmail] = useState('');
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (IsEmpty(query.email)) {
      toast.error('Looks like the link is invalid. Please contact support for assistance on this matter.');
      setIsResendDisabled(true);
    } else {
      setEmail(query.email);
    }
  }, []);

  const onResendClicked = () => {
    setIsResendDisabled(true);
    Api.resendVerifyUserEmail(email)
      .then(() => {
        toast.success('New email has been sent. Please check your inbox/promotion folder.');
      })
      .catch((err) => {
        if (err.message === 'Account has been verified. Please login.') {
          toast.info(err.message);
        } else {
          toast.error(err.message);
          toast.error('Please contact Support for assistance');
        }
      });
  };

  return (
    <div className="container" style={{ paddingTop: '5%' }}>
      <div className="row d-flex flex-column align-items-center">
        <Typography variant="h4">Email Verification</Typography>

        <Typography className="mt-5" variant="subtitle1">
          {`An email has been sent to `}
          <span style={{ fontWeight: 'bold' }}>{`${email}`}</span>
        </Typography>

        <Typography className="mt-5" variant="subtitle1">Please check your email inbox/promotion/junk folder for the verification email</Typography>
        <Typography variant="subtitle1">and click on the link in the email to verify</Typography>
        <Typography variant="subtitle1">*The link is valid for 15 mins only</Typography>

        <Typography className="mt-5" variant="caption">
          Did not receive email?
          {' '}
          <span>
            <Button
              disabled={isResendDisabled}
              onClick={() => onResendClicked()}
            >
              <Typography variant="caption">Click here to Resend</Typography>
            </Button>
          </span>
        </Typography>

      </div>
    </div>
  );
};

export default UserVerificationSent;
