import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { toast } from 'react-toastify';

import theme from './styles/theme';
import './assets/css/app.css';
import './assets/css/media.css';

import './assets/sass/style.scss';
import { store, persistor } from './store';
import { setAuthToken } from './services/axioConfig';

import history from './history';
import PageNotFound from './components/PageNotFound';
import { Url } from './common/constants';

import BusinessContainer from './sites/Business/BusinessContainer';
import ForgetPassword from './sites/ForgetPassword';
import Login from './sites/Login';
import ResetPassword from './sites/ResetPassword';
import Signup from './sites/Signup';
import UserVerificationSent from './sites/UserVerificationSent';
import UserVerification from './sites/UserVerification';

if (store.getState().auth && store.getState().auth.token) {
  setAuthToken(store.getState().auth.token);
}

toast.configure({
  position:        'top-right',
  autoClose:       3000,
  closeOnClick:    true,
  hideProgressBar: true,
  pauseOnHover:    true,
});

const RenderIfUnauthenticated = (component, properties) => {
  if (store.getState().auth.isAuthenticated) {
    const storeState = store.getState();
    return (
      <BusinessContainer
        auth={storeState.auth}
        layout={storeState.layout}
        match={properties.match}
        history={properties.history}
      />
    );
  } else {
    return component;
  }
};

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <Route exact path={Url.Signup} render={(props) => RenderIfUnauthenticated(<Signup />, props)} />
            <Route exact path={Url.Login} render={(props) => RenderIfUnauthenticated(<Login />, props)} />
            <Route exact path={Url.ForgetPassword} render={(props) => RenderIfUnauthenticated(<ForgetPassword />, props)} />
            <Route exact path={Url.ResetPassword} render={(props) => RenderIfUnauthenticated(<ResetPassword />, props)} />
            <Route path={Url.UserVerificationSent} render={(props) => RenderIfUnauthenticated(<UserVerificationSent />, props)} />
            <Route path={Url.UserVerification} render={(props) => RenderIfUnauthenticated(<UserVerification />, props)} />

            {/**
             * For the business sites to contain the correct props.match.params data,
             * we need to indicate the path for each of the sites here.
            */}

            <Route exact path={Url.Access} component={BusinessContainer} />
            <Route exact path={Url.Account} component={BusinessContainer} />
            <Route exact path={Url.Analytics} component={BusinessContainer} />
            <Route exact path={Url.Analytic} component={BusinessContainer} />
            <Route exact path={Url.Business} component={BusinessContainer} />
            <Route exact path={Url.BusinessProfile} component={BusinessContainer} />
            <Route exact path={Url.CouponsAndVouchers} component={BusinessContainer} />
            <Route exact path={Url.CreatePageClaim} component={BusinessContainer} />
            <Route exact path={Url.Invitation} component={BusinessContainer} />
            <Route exact path={Url.GeographicBoundary} component={BusinessContainer} />
            <Route exact path={Url.Outlets} component={BusinessContainer} />
            <Route exact path={Url.Outlet} component={BusinessContainer} />
            <Route exact path={Url.PageClaimView} component={BusinessContainer} />
            <Route exact path={Url.Page} component={BusinessContainer} />
            <Route exact path={Url.PageInfo} component={BusinessContainer} />
            <Route exact path={Url.ParticipateOutlets} component={BusinessContainer} />
            <Route exact path={Url.Post} component={BusinessContainer} />
            <Route exact path={Url.Posts} component={BusinessContainer} />
            <Route exact path={Url.FoodReviewHighlight} component={BusinessContainer} />
            <Route exact path={Url.GenericHighlight} component={BusinessContainer} />
            <Route exact path={Url.Gift} component={BusinessContainer} />
            <Route exact path={Url.PageHighlightListing} component={BusinessContainer} />
            <Route exact path={Url.PageHighlight} component={BusinessContainer} />
            <Route exact path={Url.PageHighlightPosts} component={BusinessContainer} />
            <Route exact path={Url.PageHighlights} component={BusinessContainer} />
            <Route exact path={Url.Review} component={BusinessContainer} />
            <Route exact path={Url.Reviews} component={BusinessContainer} />
            <Route exact path={Url.Profile} component={BusinessContainer} />
            <Route exact path={Url.Support} component={BusinessContainer} />
            <Route exact path={Url.LoyaltyCard} component={BusinessContainer} />
            <Route exact path={Url.LoyaltyCards} component={BusinessContainer} />
            <Route exact path={Url.StampSettings} component={BusinessContainer} />

            <Route exact path={Url.Main} render={(props) => RenderIfUnauthenticated(<Signup />, props)} />

            <Route component={PageNotFound} />
          </Switch>
          {/* <Modal /> */}
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export default App;
